/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'elements/Hero/HeroDefault'
import Breadcrumb from 'elements/Breadcrumb'

// Components
import SEO from 'shared/SEO'
import Layout from 'layout/Layout'
import ParseContent from 'shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Row = styled.div``

const Content = styled(ParseContent)``

const PageTemplate = ({
  data: {
    page: { title, seo, paginaPrivacy },
  },
  pageContext,
}) => {
  return (
    <Layout>
      <SEO seo={seo} />

      <HeroDefault fields={paginaPrivacy.paginaprivacyverklaringbanner} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <div className="container py-lg-5">
        <Row className="row justify-content-center">
          <div className="col-lg-9">
            <Content
              content={paginaPrivacy.paginaprivacyverklaringcontent.story}
            />
          </div>
        </Row>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wpPage(databaseId: { eq: $wordpress_id }) {
      ...PrivacyPageFrag
    }
  }
`

export default PageTemplate
